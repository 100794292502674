<!-- eslint-disable vue/no-v-html -->
<template>
	<WSection :title="$t('customer.administration.transfer_customer') + ' :'">
		<v-flex v-t="'customer.administration.transfer_customer_desc'" xs12 />
		<v-flex text-xs-right xs12>
			<WButton color="warning" flat @click="openDialog">{{ $t('customer.administration.transfer_customer') }}</WButton>
			<WDialog v-model="dialog" max-width="500" :title="$t('customer.administration.transfer_customer')" warning>
				<v-flex my-2 v-html="$t('customer.administration.transfer_customer_dialog.first', { name: company.company })" />
				<v-flex v-t="'customer.administration.transfer_customer_dialog.second'" my-2 />
				<v-flex v-t="'customer.administration.transfer_customer_dialog.third'" my-2 />
				<v-flex my-2>
					<v-autocomplete v-model="selectedAccountingFirmId" :items="accountingFirmsList" item-text="name" item-value="id" :loading="loading" />
				</v-flex>
				<template v-slot:actions>
					<WButton
						color="warning"
						class="ml-auto mr-0"
						:disabled="!selectedAccountingFirmId || selectedAccountingFirmId === accountingFirmId"
						flat
						@click="transferCustomer()"
						>{{ $t('customer.administration.transfer_customer') }}</WButton
					>
				</template>
			</WDialog>
		</v-flex>
	</WSection>
</template>

<script>
import { listAccountingFirms } from "@/services/Holding/CustomersService";
import CustomersManagerModuleGuard from '@/mixins/ModulesGuards/CustomersManager/CustomersManagerModuleGuard'

export default {
	name: 'CompanyTransferer',
	mixins: [CustomersManagerModuleGuard],
	props: {
		company: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			accountingFirms: [],
			selectedAccountingFirmId: null,
			dialog: false,
			loading: false
		}
	},
	computed: {
		accountingFirmsList: function () {
			return this.accountingFirms.filter(accountingFirm => accountingFirm.id !== this.accountingFirmId)
		}
	},
	methods: {
		getAccountingFirms: async function () {
			this.loading = true
			try {
				const accountingFirms = await listAccountingFirms(this.selectedAccountingFirm.holding_id);
				this.accountingFirms.splice(0, this.accountingFirms.length, ...accountingFirms)
			} finally {
				this.loading = false
			}
		},
		openDialog: function () {
			if (this.accountingFirms.length === 0) {
				this.getAccountingFirms()
			}
			this.dialog = true
		},
		transferCustomer: function () {
			const vendorId = this.company.id
			return this.service.transferCustomer(vendorId, this.selectedAccountingFirmId)
		}
	}
}
</script>
