// ============================================================================
// CustomersApi
// -------------------
// Customers api
// ============================================================================

// -------
// Imports
// -------
import { Backend } from '@/plugins/axios/Configurator'
import URLS from '@/apis/BackEndAPIEndpoints'

import { RequiredArguments } from '@/helpers/methods'

// -------
// Exports
// -------
export default {
    catalogTreeStructure: {
        catalogThemes: {
            read: function (catalogTreeStructureId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({ catalogTreeStructureId })
                return Backend.GET(URLS.api.catalogTreeStructures.catalogThemes.uri, [catalogTreeStructureId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
            }
        }
    },
    holding: {
        accountingFirm: {
            siret: {
                vendorData: {
                    read: function (holdingId, accountingFirmId, siret, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                        RequiredArguments({ holdingId, accountingFirmId, siret })
                        return Backend.GET(URLS.api.holdings.accountingFirms.siret.vendorData.uri, [holdingId, accountingFirmId, siret], params, doCancelPreviousRequest, doCancelAllOtherRequests)
                    }
                }
            },
            user: {
                read: function (holdingId, accountingFirmId, userId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    RequiredArguments({ holdingId, accountingFirmId, userId })
                    return Backend.GET(URLS.api.holdings.accountingFirms.users.uri, [holdingId, accountingFirmId, userId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
                }
            },
            users: {
                read: function (holdingId, accountingFirmId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    RequiredArguments({ holdingId, accountingFirmId })
                    return Backend.GET(URLS.api.holdings.accountingFirms.users.uri, [holdingId, accountingFirmId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
                }
            },
            vendor: {
                create: function (holdingId, accountingFirmId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    RequiredArguments({ holdingId, accountingFirmId })
                    return Backend.POST(URLS.api.holdings.accountingFirms.vendors.uri, [holdingId, accountingFirmId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
                },
            },
            vendors: {
                catalogTreeStructures: {
                    read: function (holdingId, accountingFirmId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                        RequiredArguments({ holdingId, accountingFirmId })
                        return Backend.GET(URLS.api.holdings.accountingFirms.vendors.catalogTreeStructures.uri, [holdingId, accountingFirmId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
                    }
                }
            },
            applications: {
                read: function(holdingId, accountingFirmId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    RequiredArguments({ holdingId, accountingFirmId })
                    return Backend.GET(URLS.api.holdings.accountingFirms.applications.uri, [holdingId, accountingFirmId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
                }
            },
            roles: {
                read: function (accountingFirmId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    RequiredArguments({ accountingFirmId })
                    return Backend.GET(URLS.api.accountingFirms.roles.uri, [accountingFirmId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
                }
            }
        },
        accountingFirms: {
            read: function (holdingId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({ holdingId })
                return Backend.GET(URLS.api.holdings.accountingFirms.uri, [holdingId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
            }
        },
        module: {
            read: function (holdingId, moduleKey, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({ holdingId, moduleKey })
                return Backend.GET(URLS.api.holdings.modules.uri, [holdingId, moduleKey], params, data, doCancelAllOtherRequests, doCancelPreviousRequest)
            }
        },
        vendor: {
            user: {
                read: function (holdingId, vendorId, userId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    RequiredArguments({ holdingId, vendorId, userId })
                    return Backend.GET(URLS.api.holdings.vendors.users.uri, [holdingId, vendorId, userId], params, data, doCancelAllOtherRequests, doCancelPreviousRequest)
                }
            }
        },
        vendors: {
            read: function (holdingId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({ holdingId })
                return Backend.GET(URLS.api.holdings.vendors.uri, [holdingId], params, data, doCancelAllOtherRequests, doCancelPreviousRequest)
            }
        }
    },
    nafCodes: {
        read: function (params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
            return Backend.GET(URLS.api.nafCodes.uri, [], params, data, doCancelAllOtherRequests, doCancelPreviousRequest)
        }
    },
    user: {
        read: function (email, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
            return Backend.GET(URLS.api.userByEmail.uri, [email], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
        }
    }
}
