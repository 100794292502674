// ============================================================================
// CustomersService
// -----------------------
// Customers module related services
// ============================================================================

// -------
// Imports
// -------
import API from '@/apis/Holding/CustomersApi'
import { RequiredArguments } from '@/helpers/methods'
import { store } from '@/store'

// ---------
// Internals
// ---------
const Private = {
	requests: {
        catalogTreeStructure: {
            catalogThemes: {
                read: function (catalogTreeStructureId, params = {}) {
                    RequiredArguments({ catalogTreeStructureId })
                    return API.catalogTreeStructure.catalogThemes.read(catalogTreeStructureId, params.params)
                }
            }
        },
        holding: {
            accountingFirm: {
                siret: {
                    vendorData: {
                        read: function (holdingId, accountingFirmId, siret) {
                            RequiredArguments({ holdingId, accountingFirmId, siret })
                            return API.holding.accountingFirm.siret.vendorData.read(holdingId, accountingFirmId, siret)
                        }
                    }
                },
                user: {
                    read: function (holdingId, accountingFirmId, userId) {
                        RequiredArguments({ holdingId, accountingFirmId, userId })
                        return API.holding.accountingFirm.user.read(holdingId, accountingFirmId, userId, { show_error: false })
                            .catch(error => {
                                if (error.response.status == 404) {
                                    return null
                                }
                                throw error
                            })
                    }
                },
                users: {
                    read: function (holdingId, accountingFirmId) {
                        RequiredArguments({ holdingId, accountingFirmId })
                        return API.holding.accountingFirm.users.read(holdingId, accountingFirmId)
                    }
                },
                vendor: {
                    create: function (holdingId, accountingFirmId, vendorData) {
                        RequiredArguments({ holdingId, accountingFirmId })
                        return API.holding.accountingFirm.vendor.create(holdingId, accountingFirmId, null, vendorData)
                    }
                },
                vendors: {
                    catalogTreeStructures: {
                        read: function (holdingId, accountingFirmId, params = {}) {
                            RequiredArguments({ holdingId, accountingFirmId })
                            return API.holding.accountingFirm.vendors.catalogTreeStructures.read(holdingId, accountingFirmId, params)
                        }
                    }
                },
                applications: {
                    read: function (holdingId, accountingFirmId, params = {}) {
                        RequiredArguments({ holdingId, accountingFirmId })
                        return API.holding.accountingFirm.applications.read(holdingId, accountingFirmId, params)
                    }
                },
                roles: {
                    read: function(accountingFirmId, params = {}) {
                        RequiredArguments({ accountingFirmId })
                        return API.holding.accountingFirm.roles.read(accountingFirmId, params)
                    }
                }
            },
            accountingFirms: {
                read: function (holdingId) {
                    RequiredArguments({ holdingId })
                    return API.holding.accountingFirms.read(holdingId)
                }
            },
            module: {
                read: function (holdingId, moduleKey) {
                    RequiredArguments({ holdingId, moduleKey })
                    return API.holding.module.read(holdingId, moduleKey, { show_error: false })
                }
            },
            vendor: {
                user: {
                    read: function (holdingId, vendorId, userId) {
                        RequiredArguments({ holdingId, vendorId, userId })
                        return API.holding.vendor.user.read(holdingId, vendorId, userId, { show_error: false })
                    }
                }
            },
            vendors: {
                read: function (holdingId, params) {
                    RequiredArguments({ holdingId })
                    return API.holding.vendors.read(holdingId, params)
                }
            }
        },
        nafCodes: {
            read: () => {
                return API.nafCodes.read()
            }
        },
        user: {
            read: function (email) {
                RequiredArguments({ email })
                return API.user.read(email, { show_error: false })
            }
        }
    },
	service: {
        holding: {
            vendor: {
                user: {
                    exists: function (holdingId, vendorId, userId) {
                        return Private.requests.holding.vendor.user.read(holdingId, vendorId, userId)
                            .then(() => true)
                            .catch(error => {
                                if (error.response.status == 404) {
                                    return false
                                }
                                throw error
                            })
                    }
                }
            }
        }
    },
	store: {
        holding: {
            module: {
                exists: function (moduleName) {
                    RequiredArguments({ moduleName: moduleName })
                    const modules = Private.store.holding.modules.get()
                    return modules.find(module => module.name == moduleName) != undefined
                }
            },
            modules: {
                get: function () {
                    return store.getters['modules/getHoldingModules']
                }
            }

        }
    }
}

// -------
// Exports
// -------
const listAccountingFirms = Private.requests.holding.accountingFirms.read;

export default {
    findAccountant: Private.requests.holding.accountingFirm.user.read,
    listAccountants: Private.requests.holding.accountingFirm.users.read,

    listAccountingFirms,

    listSubscriptions: Private.requests.holding.accountingFirm.applications.read,

    listCatalogThemes: Private.requests.catalogTreeStructure.catalogThemes.read,
    listCatalogTreeStructures: Private.requests.holding.accountingFirm.vendors.catalogTreeStructures.read,

    createCustomer: Private.requests.holding.accountingFirm.vendor.create,
    getRoles: Private.requests.holding.accountingFirm.roles.read,

    findCustomerData: Private.requests.holding.accountingFirm.siret.vendorData.read,

    findModule: Private.requests.holding.module.read,
    hasModule: Private.store.holding.module.exists,

    listNafCodes: Private.requests.nafCodes.read,

    findUserByEmail: Private.requests.user.read,

    hasAccessToVendor: Private.service.holding.vendor.user.exists,
    listVendors: Private.requests.holding.vendors.read
}

export {
    listAccountingFirms,
}
